<template>
<div style="height: 100%; position: relative; overflow:hidden;">
    <div id="map-container" style="height: 100%;">
    </div>
    <v-menu bottom left style="z-index:1050;">
        <template v-slot:activator="{ on }">
            <v-btn 
                fixed dark fab bottom right color="orange" style="opacity: 0.80; top:80px; z-index:1050;" 
                elevation-1 v-on="on" title="Changer le fond de carte">
                  <v-icon>mdi-layers</v-icon>
            </v-btn>
        </template>
            <v-list dense class="rounded-lg">
                <template v-for="(item, pindex) in tileLayer">
                <v-list-item :key="pindex" @click="changeTileLayer(pindex)">
                  <v-list-item-action>
                      <img :src="item.thumbnail" width="65" class="rounded-lg" :style="(activeTile == pindex) ? 'border: 3px solid orange;' : ''" />
                    </v-list-item-action>
                  <v-list-item-title>{{item.text}}</v-list-item-title>
                </v-list-item>
                </template>
            </v-list>
    </v-menu>
    <v-btn 
        fixed dark fab bottom right :color="(addAlertState) ? 'red' : 'grey'" style="opacity: 0.80; top:150px; z-index:1040;" 
        elevation-1 @click="activeAlert(!addAlertState)" title="Créer une alerte">
        <v-icon>mdi-alert</v-icon>
    </v-btn>
    <v-btn 
        fixed dark fab bottom right :color="'grey'" style="opacity: 0.80; top:220px; z-index:1040;" 
        elevation-1 title="Afficher les vehicules">
        <v-icon>mdi-truck</v-icon>
    </v-btn>
    <v-btn 
        fixed dark fab bottom right :color="(districtState) ? 'green' : 'grey'" style="opacity: 0.80; top:290px; z-index:1040;" 
        elevation-1 @click="displayDistrict()" title="Afficher les arrondissements">
        <v-icon>mdi-map-outline</v-icon>
    </v-btn>
    <v-btn v-show="(canDisplayTree || addDisplayTree)"
        :loading="loading.getAllTree"
        fixed dark fab bottom right :color="(addDisplayTree) ? 'green' : 'primary'" style="opacity: 0.80; top:360px; z-index:1040;" 
        elevation-1 @click="displayAllTree(!addDisplayTree)" title="Afficher tous les arbres">
        <v-icon>mdi-tree</v-icon>
    </v-btn>

    <div style="min-width:25%; position:absolute; left:10px; top:20px; z-index: 899; display: inline;">
        <v-autocomplete
        v-model.trim="searchAddress"
        label="Rechercher"
        :items="addressTab"
        item-value="id" item-text="label"
        :search-input.sync="search"
        @input="panTo" @focus="searchBoxClosed = false" @blur="searchBoxClosed = true"
        append-icon="mdi-magnify" filled outlined dense clearable no-filter
        class="inline rounded-xl shrink expanding-search" :class="{ closed: (searchBoxClosed && searchAddress == null) }"
        background-color="white" style="opacity: 0.7;">
        </v-autocomplete>
        <!-- Filter by district -->
        <v-autocomplete
        v-model="searchDistrict"
        label="Arrondissement" multiple chips small-chips
        :items="districtTab"
        item-value="id" item-text="label"
        @input="displayTask(true)" @focus="districtBoxClosed = false" @blur="districtBoxClosed = true"
        append-icon="mdi-filter-outline" filled outlined dense clearable no-filter
        class="ml-4 inline rounded-xl shrink expanding-search" :class="{ closed: (districtBoxClosed && !searchDistrict.length) }"
        background-color="white" style="opacity: 0.7;">
        </v-autocomplete>
        <!-- Filter by district -->
        <v-autocomplete
        v-model="searchProtection"
        label="Protection" multiple chips small-chips
        :items="protectionTab"
        item-value="id" item-text="label"
        @input="displayTask(true)" @focus="protectionBoxClosed = false" @blur="protectionBoxClosed = true"
        append-icon="mdi-filter-outline" filled outlined dense clearable no-filter
        class="ml-4 inline rounded-xl shrink expanding-search" :class="{ closed: (protectionBoxClosed && !searchProtection.length) }"
        background-color="white" style="opacity: 0.7;">
        </v-autocomplete>
    </div>
    <v-card elevation="2" class="rounded-lg" style="position:absolute; left:10px; top:80px; z-index: 890;">
        <div>
        <v-btn class="ma-1" small outlined text @click="drawObject('Rectangle')" title="Selection rectangle">
            <v-icon>mdi-vector-square</v-icon>
        </v-btn>
        </div>
        <div>
        <v-btn class="ma-1" small outlined text @click="drawObject('Polygon')" title="Selection polygone">
            <v-icon>mdi-vector-polyline</v-icon>
        </v-btn>
        </div>
    </v-card>
    <!-- ------------Alerte view ----------------- -->
    <v-dialog v-model="modalEvent" max-width="900" style="z-index:2066;">
        <v-card min-width="350px" flat class="rounded-lg">
        <v-toolbar color="primary" dark flat height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>{{selectionEvent.title}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="switchModal('modalEvent', 0)"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="elevation-0 pa-0">
            <edit-event 
                v-if="modalEvent" :switchModal="switchModal"
                :xxxId="selectionEvent.xxxId" :eveId="selectionEvent.eveId" 
                :eveTable="selectionEvent.eveTable" :eveType="selectionEvent.eveType" 
                :title="selectionEvent.title" :cooridnates="selectionEvent.cooridnates">
            </edit-event>
        </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Selection d'arbres rapport -->
    <v-dialog v-if="selectionDialog" v-model="selectionDialog" max-width="600px">
    <v-card>
        <v-toolbar color="primary" dark class="elevation-1" height="50px">
            <v-toolbar-title>Arbres selectionnées : {{ selectionItems.length }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu :ref="'tsk_date_plan_set_all'" v-model="dateMenu['tsk_date_plan_set_all']"
                :return-value.sync="tsk_date_plan_set_all"
                :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                offset-y min-width="290px" style="z-index:1500">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn rounded small color="green" v-on="on" v-bind="attrs">
                        <span v-if="tsk_date_plan_set_all">{{ locDateFormat.toLocale(tsk_date_plan_set_all) }}</span>
                        <span v-else>Plannifier</span>
                    <v-icon>mdi-playlist-edit</v-icon></v-btn>
                </template>
                <v-date-picker locale="fr-fr" no-title scrollable v-model="tsk_date_plan_set_all">
                    <v-btn color="red" @click="tsk_date_plan_set_all = null"><v-icon>mdi-eraser</v-icon></v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="dateMenu.tsk_date_plan_set_all = false">Annuler</v-btn>
                    <v-btn color="primary" @click="$refs['tsk_date_plan_set_all'].save( tsk_date_plan_set_all ); setAllPlan()">OK</v-btn>
                </v-date-picker>
            </v-menu>
            <v-btn icon color="" @click.native="selectionDialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pt-2 pl-0 pr-0">
            <v-data-table v-bind:headers="treeHeaders" :items="selectionItems" item-key="tsk_id" :search="search"
                :disable-pagination="true" hide-default-footer height="500px" fixed-header>
                <template v-slot:[`item.tre_address`]="{ item }">
                    {{ item.tre_loc_no + ' ' + item.tre_address  }}
                </template>
                <template v-slot:[`item.tsk_typ_id`]="{ item }">
                    <v-chip small outlined label :color="getCacheType('TSK', item.tsk_typ_id).typ_color">{{ getCacheType('TSK', item.tsk_typ_id).typ_name }}</v-chip>
                </template>
                <template v-slot:[`item.tsk_vol`]="{ item }">{{item.tsk_vol.toFixed(2)}}</template>
                <template v-slot:[`item.tsk_date_plan`]="{ item }">
                    <v-menu :ref="'tsk_date_plan' + item.tsk_id" v-model="dateMenu['tsk_date_plan' + item.tsk_id]"
                        :return-value.sync="item.tsk_date_plan"
                        :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                        offset-y min-width="290px" style="z-index:1500">
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip small outlined color="green" v-on="on" v-bind="attrs">
                                <span v-if="item.tsk_date_plan">{{ locDateFormat.toLocale(item.tsk_date_plan) }}</span>
                                <v-icon v-else color="green">mdi-calendar-edit</v-icon>
                            </v-chip>
                        </template>
                        <v-date-picker locale="fr-fr" no-title scrollable v-model="item.tsk_date_plan">
                            <v-btn color="red" @click="item.tsk_date_plan = null"><v-icon>mdi-eraser</v-icon></v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="dateMenu['tsk_date_plan' + item.tsk_id] = false">Annuler</v-btn>
                            <v-btn color="primary" @click="$refs['tsk_date_plan' + item.tsk_id].save( item.tsk_date_plan )">OK</v-btn>
                        </v-date-picker>
                    </v-menu>
                </template>
            </v-data-table>
        </v-card-text>
        <v-card-actions class="grey lighten-3">
            <v-chip small outlined label color="green">
                Volume: {{ Math.round( selectionItems.map(selectionItems => selectionItems.tsk_vol).reduce((prev, curr) => prev + curr, 0) * 100 ) / 100 }}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn rounded text color="#595959" dark @click.native="selectionDialog = false">
                <v-icon left dark>mdi-close</v-icon>Annuler
            </v-btn>
            <v-btn rounded color="success darken-1" :loading="saveProgress" :disabled="saveProgress" @click.native="saveSelectionCard()">
                <v-icon left dark>mdi-content-save-outline</v-icon>Enregistrer
            </v-btn>
        </v-card-actions>
    </v-card>
    </v-dialog>
</div>
</template>

<script>
import dateLocale from '../services/dateLocale';
import LfMap        from '../plugins/map';
import { cacheGetters, cacheData } from "../store/cache.module"
import { bus } from '@/plugins/bus'
import { tskObj }       from '../mixins/tsk_obj.js'
import editEvent  from '../components/Edit_event.vue'
var mapthis = { map: {} }
import { taskUpdateService } from '@/rxjsServices';
import { filterUpdateService } from '@/rxjsServices';

export default {
    name: 'maptool',
    props: ['filterView'],
    mixins: [ tskObj ],
    data: function () {
        return {
            saveProgress: false,
            locDateFormat: dateLocale,
            fieldStyle: { outlined: true, dense: true },
            map: null,
            loading: { getVersion: false, getMap: false, filters: [], getAllTree: false },
            alertDialog: false,
            madeDialog: false, colorDialog: false,
            attribDialog: false,
            botNav: { height: 55 },
            layerMenu: false,
            filterMenu: false,
            versionMenu: false,
            blockMenu: false, blockMenuGen: false,
            popup: false, 
            dealIcon: false,
            dealTab: [],
            deal: {},
            drawEdit: false, dialogObj:false, dialogLayer: false, modalEvent: false,
            selectionDialog: false,
            selectionItems: [],
            selectionEvent: { xxxId: [], eveId:0, eveTable: "cad", eveType: "ALT", title:"Alertes", cooridnates:null },
            dxfObject: {},
            selectList : this.$storage.localStorage.get( this.$APP_COD + "_cache" ),
            state      : this.$storage.localStorage.get( this.$APP_COD + "_cache" ),
            objectList : {},
            lraObj: { lra_tem_id:1, lra_obt_id:1, lra_source_id: '', color: '#000000' },
            layerList: [], allLayerList: [], objLayerList: {},
            dxfLayer: [], dxfBlocks: {}, genBlocks: {}, dxfVersion: [],
            layerLetter: [],
            stateLetter: [],
            letterDisplay: 0,
            drawSaved: true,
            projection: 'EPSG3857',
            toolBar: 1,
            toolMode: 0,
            drawState: { editMode: false, dragMode: false, rotation:0 },
            alertObj : {
                alt_id : 0, alt_lat : 0, alt_lng : 0
            },
            loadAlert: false,
            loadTask: false,
            verTab: {},
            lraColor: { lra_id:0, lra_color:'', lra_source_id:'' },
            tabVersion: {},
            layerAction: 'copy',
            cardVersionType: '',
            filters: [],
            pro_id: 0, //this.user.usr_pro_id,
            modalFilter: false,
            filterInfo: {},
            layerSource: true,
            activeTile: 'ign',
            tileLayer: {
                ign : { text: 'Fond de plan IGN',   thumbnail: require('../assets/plan_ign.jpg'),   link: 'https://{s}.tiles.technosig.fr/' + 'rj3ry0gjlkxx7feia0mx43ut' + '/wmts/osmtiles/webmercator/{z}/{x}/{y}.png', color: 'green', icon: 'mdi-check'},
                osm : { text: 'Fond de plan OSM',   thumbnail: require('../assets/plan_osm.jpg'),   link: 'https://{s}.tiles.technosig.fr/' + 'rj3ry0gjlkxx7feia0mx43ut' + '/wmts/arcgistiles/webmercator/{z}/{x}/{y}.png', color: 'green', icon: 'mdi-check'},
                bph : { text: 'Fond de plan photo', thumbnail: require('../assets/plan_photo.jpg'), link: 'https://{s}.tiles.technosig.fr/' + 'rj3ry0gjlkxx7feia0mx43ut' + '/wmts/igntiles/webmercator/{z}/{x}/{y}.png', color: 'green', icon: 'mdi-check'},
                bwt : { text: 'Sans fond',          thumbnail: require('../assets/plan_sans.jpg'),  link: '', color: 'green', icon: 'mdi-check'},
            },
            addSelectionState: false,
            addMarkerState: false,
            addTreeState: false,
            addAlertState: false,
            addDisplayTree: false,
            locatemeState: false,
            searchAddress: null,
            searchDistrict: [],
            searchProtection: [],
            addressTab: [],
            districtTab: [],
            protectionTab: [],
            search: null,
            searchWord: '',
            timeOutHandle: 'off',
            filter_table: {}, //this.user.filter_table_id //this.$auth.userPrefuser.filter_table_id,
            current_prv_id: 0,
            swatches: [
                ['#FF0000', '#AA0000', '#550000'], ['#FFFF00', '#AAAA00', '#555500'], ['#00FF00', '#00AA00', '#005500'],
                ['#00FFFF', '#00AAAA', '#005555'], ['#0000FF', '#0000AA', '#000055'],
            ],
            layerColor: '',
            layerByVersion: { 'VER02': [], 'VER04': [] },
            canDisplayTree: false,
            districtState: false,
            dateMenu: { tsk_date_plan_set_all: null },
            tsk_date_plan_set_all: null,
            treePagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            treeHeaders: [
                { text: 'Id',      align: 'left',  value: 'tsk_id'},
                { text: 'Adresse', align: 'left',  value: 'tre_address'},
                { text: 'Etat',    align: 'left',  value: 'tsk_typ_id'},
                { text: 'Volume',  align: 'right', value: 'tsk_vol'},
                { text: 'Date',    align: 'right',  value: 'tsk_date_plan'},
            ],
            fitBound: true,
            searchBoxClosed: true,
            districtBoxClosed: true,
            protectionBoxClosed: true
        }
    },
    watch: {
        search (val) {
            this.searchWord = val
            if( this.timeOutHandle == 'off' && val ){
                this.timeOutHandle = 'on'
                clearTimeout(this.searchTempo)
                this.searchTempo = setTimeout( () => {
                    if( isNaN(this.searchWord) ){
                        this.loadAddress(this.searchWord)
                    } else {
                        this.searchTreeByExtid(this.searchWord)
                    }
                    
                    this.timeOutHandle = 'off'
                }, 1500)
            }
        },
    },
    computed: {
        user(){
            return cacheData.user
        },
    },
    created(){
        this.subTask = taskUpdateService.getTask().subscribe(message => {
            if (message) {
                message.color = this.getCacheType('TSK', message.tsk_typ_id).typ_color
                message.color = (message.tsk_sta_id == 'GEN03') ? this.getCacheState('GEN', message.tsk_sta_id).sta_color : message.color
                mapthis.map.updateTask( message )
            }
        })
        this.subFilter = filterUpdateService.getFilter().subscribe(message => {
            if (message) {
                this.displayTask()
                this.loadAlert = !this.loadAlert
                this.displayAlert()
            }
        })
    }, 
    beforeMount() {
        this.filter_table = this.user.filter_table_id
    },
    mounted() {
        mapthis.map = new LfMap('map-container')
        bus.$on('alert:open',  (obj) => this.viewAlert(obj))
        bus.$on('task:open',   (obj) => this.showObjectCard(obj))
        bus.$on('task:select', (obj) => this.showSelectionCard(obj))
        bus.$on('tree:get',    (obj) => this.zoomToGetTree(obj))

        if( this.$route.params.latlng !== undefined && this.$route.params.latlng.length > 0 ){
            mapthis.map.mapCenter = this.$route.params.latlng.split(',')
        }

        this.initMap()        
    },
    methods:{
        async initMap(){
            await this.displayMap()
            await this.displayTask()
            this.displayAlert()
        },
        displayMap() {
            return new Promise( (resolve) => {
                if( cacheData.pro_projection == 'CRS' ){
                    this.projection = 'Simple'
                }
                mapthis.map.display( [0, 0], 2, this.projection, this.lraObj, this.showObjectCard, this.drawSaved, this.$root.$toast )
                resolve('resolved')
            })
        },
        displayTask(filterTask){
            return new Promise( (resolve, reject) => {
                filterTask = filterTask || false
                mapthis.map.removeAllTask()
                this.loadTask = true
                if( this.loadTask && !filterTask ){
                    let url    = '?per_page=false&extended=withtree,withactivealert'
                    let option = cacheGetters.getFilterUrl()
                    url    += ( option.length ) ? '&' + option : ''
                    this.getTaskIndex(false, false, url).then( () => {
                        this.searchDistrict = []
                        let districts = []
                        let protections = []
                        let kp, vp
                        for (kp = 0; (vp = this.listTask[kp]) !== undefined; kp++) {
                            if( vp.tre_lat && vp.tre_lng ){
                                vp.color = this.getCacheType('TSK', vp.tsk_typ_id).typ_color
                                vp.color = (vp.tsk_sta_id == 'GEN03') ? this.getCacheState('GEN', vp.tsk_sta_id).sta_color : vp.color
                                mapthis.map.addTask( vp )
                                vp.tre_district = ( !vp.tre_district ) ? 'Non renseigné' : vp.tre_district
                                districts.push(vp.tre_district)
                                if( vp?.tsk_custom_fields?.tsk_prot_type ){
                                    vp.tsk_custom_fields.tsk_prot_type = ( !vp.tsk_custom_fields.tsk_prot_type ) ? 'Non renseigné' : vp.tsk_custom_fields.tsk_prot_type
                                    protections.push(vp.tsk_custom_fields.tsk_prot_type)
                                } // todoo ajouter les vide ou innexistants
                            }
                        }
                        // display by district
                        this.districtTab = districts.filter( function onlyUnique(value, index, array) {
                            return array.indexOf(value) === index;
                        })
                        // protection tab
                        this.protectionTab = protections.filter( function onlyUnique(value, index, array) {
                            return array.indexOf(value) === index;
                        })

                        if( kp && this.fitBound && mapthis.map.mapCenter == null ){
                            mapthis.map.fitBoundLayerGroup()
                            this.fitBound = false
                        }
                        resolve('resolved')
                    }).catch( (error) => { 
                        reject(error)
                    })
                } else if (filterTask){ //&& (this.searchDistrict.length || this.searchProtection.length)
                        let kp, vp
                        for (kp = 0; (vp = this.listTask[kp]) !== undefined; kp++) {
                            if( vp.tre_lat && vp.tre_lng && (!this.searchDistrict.length || this.searchDistrict.includes(vp.tre_district) ) && (!this.searchProtection.length || this.searchProtection.includes(vp.tsk_custom_fields?.tsk_prot_type) ) ){
                                vp.color = this.getCacheType('TSK', vp.tsk_typ_id).typ_color
                                vp.color = (vp.tsk_sta_id == 'GEN03') ? this.getCacheState('GEN', vp.tsk_sta_id).sta_color : vp.color
                                mapthis.map.addTask( vp )
                            }
                        }
                } else {
                    mapthis.map.removeAllAlert()
                    resolve('resolved')
                }
            })
        },
        zoomToGetTree(zoom){
            if( zoom >= 17 ){
                this.canDisplayTree = true
            } else {
                this.canDisplayTree = false
            }
        },
        displayTree(bound){
            return new Promise( (resolve, reject) => {
                mapthis.map.removeAllTree()
                this.loadTask = true
                let zonePram = '&min_tre_lat=' + bound._southWest.lat + '&min_tre_lng=' + bound._southWest.lng
                zonePram    += '&max_tre_lat=' + bound._northEast.lat + '&max_tre_lng=' + bound._northEast.lng
                if( this.loadTask ){
                    this.$http.get( '/trees/?per_page=false&extended=withoutinfo&min_tre_lat=1' + zonePram ).then( (response) => {
                        var kp, vp
                        for (kp = 0; (vp = response.data.data[kp]) !== undefined; kp++) {
                            if( vp.tre_lat && vp.tre_lng ){
                                vp.color = 'grey'
                                mapthis.map.addTree( vp )
                            }
                        }
                        resolve('resolved')
                    }).catch( (error) => { 
                        reject(error)
                    })
                } else {
                    mapthis.map.removeAllAlert()
                    resolve('resolved')
                }
            })
        },
        async displayAllTree(state){
            this.loading.getAllTree = true
            let bound = mapthis.map.Map.getBounds()
            this.addDisplayTree = state
            if( this.addDisplayTree ){
                mapthis.map.removeAllTask()
                await this.displayTree(bound)
                this.loading.getAllTree = false
            } else {
                mapthis.map.removeAllTree
                await this.displayTask()
                this.loading.getAllTree = false
            }
        },
        closePopup(){
            this.dealIcon = false
            mapthis.map.clearDealMarker()
        },
        displayAlert(){
            mapthis.map.removeAllAlert()
            this.loadAlert = !this.loadAlert
            if( this.loadAlert ){
                let option = cacheGetters.getFilterUrl('sta')
                option     = (option.length) ? 'eve_' + option.substring(4) : ''
                this.$http.get( '/events/?' + option + '&eve_typ_id=%ALT%' ).then( (response) => {
                    var kp, vp
                    for (kp = 0; (vp = response.data.data[kp]) !== undefined; kp++) {
                        if( vp.eve_lat && vp.eve_lng ){
                            mapthis.map.addAlert( vp )
                        }
                    }
                })
            } else {
                mapthis.map.removeAllAlert()
            }
        },
        closeAlert(){
            this.alertDialog = false
        },
        activeAlert(state){
            this.addAlertState = state
            mapthis.map.activeAlert(state)
        },
        displayDistrict(){
            this.districtState = !this.districtState
            this.$http.customRequest( {
                headers: '',
                baseURL: '',
                method: 'get',
                url: "ard.geojson"
            })
            .then( (response) => {
                mapthis.map.displayDistrict(response.data, this.districtState)
            })
        },
        showObjectCard( obj ){
            //this.dialogObj = true
            if( obj.tsk_id ){
                this.$router.push({ path: '/map/sheet/' + obj.tsk_id })
            } else if( obj.tre_id ){
                this.$router.push({ path: '/map/create/' + obj.tre_id })
            }
            
        },
        showSelectionCard(bound){
            this.tsk_date_plan_set_all = null
            let zonePram = '&min_tre_lat=' + bound._southWest.lat + '&min_tre_lng=' + bound._southWest.lng
            zonePram    += '&max_tre_lat=' + bound._northEast.lat + '&max_tre_lng=' + bound._northEast.lng
            let url    = '?per_page=false&extended=withtree,withactivealert'
            let option = cacheGetters.getFilterUrl()
            url    += ( option.length ) ? '&' + option : ''
            url    += zonePram
            this.getTaskIndex(1, false, url).then( () => {
                this.listTask.forEach(element => {
                    this.dateMenu['tsk_date_plan' + element.tsk_id]
                    element['tre_loc_no']  = ( element['tre_loc_no'] === null ) ? '-' : element['tre_loc_no']
                    element['tre_address'] = ( element['tre_address'] === null ) ? '-' : element['tre_address']
                    element['tsk_vol'] = Math.round( parseFloat(element.tsk_base_lenght) * parseFloat(element.tsk_base_width) * parseFloat(element.tsk_base_depth) * 100 ) / 100
                });
                this.selectionItems  = this.listTask
                this.selectionDialog = true
            })
        },
        async saveSelectionCard(){
            this.saveProgress = true
            for (const task of this.listTask) {
                let element = await this.checkTaskState(task)
                await this.putTaskStore( { tsk_sta_id: 'GEN01', tsk_id: element.tsk_id, tsk_typ_id: element.tsk_typ_id, tsk_date_plan: element.tsk_date_plan } )
                taskUpdateService.sendTask({ tsk_sta_id: 'GEN01', tsk_id: element.tsk_id, tsk_typ_id: element.tsk_typ_id, tsk_date_plan: element.tsk_date_plan });
            }
            setTimeout( () => {
                //this.displayTask()
                this.switchModal('selectionDialog')
                this.saveProgress = false
            }, 700)
        },
        setAllPlan(){
            this.selectionItems.forEach(element => {
                element.tsk_date_plan = this.tsk_date_plan_set_all
            })
        },
        switchModal(name, mode){
            name    = name || 'dialogObj'
            mode    = mode || false
            //refresh = refresh || false
            this[name] = mode
            if( name == 'modalEvent' && !mode ){
                this.loadAlert = false
                this.displayAlert()
                this.activeAlert(false)
            }
        },
        viewAlert( altObj ){
            this.selectionEvent.xxxId    = this.pro_id
            this.selectionEvent.eveId    = altObj.eve_id
            this.selectionEvent.eveTable = 'ses'
            this.selectionEvent.eveType  = "ALT"
            this.selectionEvent.title    = 'Alerte'
            this.selectionEvent.cooridnates    = { lat: altObj.eve_lat, lng: altObj.eve_lng }
            //this.alertDialog = true
            this.switchModal('modalEvent', 1)
        },
        locateMe(){
            this.locatemeState = !this.locatemeState
            mapthis.map.locateMe(this.addMarkerState, this.locatemeState)
        },
        changeTileLayer(id){
            this.activeTile = id
            mapthis.map.setTileLayer(this.tileLayer[id].link)
        },
        panTo(){
            if( this.searchAddress !== null && this.searchAddress !== undefined ){
                var coor = this.addressTab[ this.searchAddress ].item.geometry.coordinates
                mapthis.map.panTo( coor )
                mapthis.map.addCircleZone( coor )
            }
        },
        loadAddress (val) {
            if( val.length > 1 ){
                this.$http.customRequest( {
                    headers: '',
                    baseURL: '',
                    method: 'get',
                    url: "https://api-adresse.data.gouv.fr/search/?q=" + val + "&limit=6"
                })
                .then( (response) => {
                    let data = []
                    let address, key
                    for (key = 0; (address = response.data.features[key]) !== undefined; key++) {
                        data[key] = { label: address.properties.label, value: address.properties.id, id:key, item: address }
                    }
                    this.addressTab = data
                })
            } else {
                return false
            }
        },
        searchTreeByExtid(val){
            this.$http.get( encodeURI('/trees/?tre_ext_id=%' + val + '%') ).then( (response) => {
                let data = []
                let key  = 0
                response.data.data.forEach(element => {
                    data.push({ 
                        label: '[' + element.tre_ext_id + '] ' + element.tre_address, value: element.tre_id, id:key, item: {
                            geometry   : { coordinates: [ element.tre_lng, element.tre_lat ] },
                            properties : {},
                            type       : {},
                        }
                    })
                    key++
                })
                this.addressTab = data
            })
        },
        drawObject(tool) {
            mapthis.map.enablePmDraw(tool);
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates,
    },
    beforeDestroy() {
        bus.$emit('off')

        var layersState = {}
        for (var item in this.objLayerList) {
            layersState[item] = this.objLayerList[item].active
        }
        cacheData.filter_layer = layersState
        //this.$auth.savePref()
        // unsubscribe to ensure no memory leaks
        this.subTask.unsubscribe()
        this.subFilter.unsubscribe()
    },
    components: {
        //'object-edit': objectEdit,
        'edit-event'   : editEvent
    }
}
</script>

<style lang="scss">
@import "../../node_modules/leaflet/dist/leaflet.css";
@import '../assets/leaflet.pm.css';
@import "../../node_modules/leaflet.markercluster/dist/MarkerCluster.css";
@import "../../node_modules/leaflet.markercluster/dist/MarkerCluster.Default.css";
@import '../assets/leaflet.css';

.dealInfo {
  position: absolute;
  top: 5px;
  right:5px;
  background-color: white;
  z-index: 500;
  max-width: 250px;
  max-width: 300px;
  max-height: 80%;
}
.leaflet-pane {
    z-index: 0;
}
.v-overlay--active{
    z-index: 1053 !important;
}
.v-dialog__content--active {
    z-index: 1055 !important;
}
.bottnavdraw {
    position: fixed;
}
.v-btn.active .v-icon {
    transform: rotate(-45deg);
}
.v-input.expanding-search {
  transition: max-width 0.5s;
}
.v-input.expanding-search.closed {
  max-width: 160px;
}
.v-autocomplete.v-select--is-menu-active .v-input__icon--append .v-icon {
    transform: none;
}
div.inline { float:left; }
</style>